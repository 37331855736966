import { Description, ErrorMessage, Field, Input, Label } from 'ui';
import { useField } from 'remix-validated-form';
import { useTranslationScope } from '~/localization/use-translation-scope';
import type { ValidationBehaviorOptions } from 'remix-validated-form/browser/internal/getInputProps';
import type { ComponentProps } from 'react';

export type ValidatedInputProps = Omit<ComponentProps<typeof Input>, 'error'> & {
  name: string;
  label?: string;
  description?: string;
  className?: string;
  validationBehavior?: Partial<ValidationBehaviorOptions>;
};

export function ValidatedInput({
  name,
  label,
  description,
  onChange: externalOnChange,
  className,
  validationBehavior = {},
  ...props
}: ValidatedInputProps) {
  const { t } = useTranslationScope('form');
  const { error, getInputProps } = useField(name, {
    validationBehavior: {
      whenSubmitted: 'onSubmit',
      whenTouched: 'onSubmit',
      initial: 'onSubmit',
      ...validationBehavior,
    },
  });

  const inputProps = getInputProps();

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    externalOnChange?.(event);
    inputProps.onChange?.(event);
  }

  const errorKey = Array.isArray(error) ? error[0] : error;
  const errorMessage = t(errorKey);

  return (
    <Field className={className}>
      {label ? (
        <Label className={props.required ? 'flex items-center justify-between' : undefined}>
          {label}
          {props.required ? <span className="text-gray-4 text-xs">{t('form:required')}</span> : null}
        </Label>
      ) : null}
      <Input
        {...getInputProps()}
        {...props}
        onChange={onChange}
        invalid={!!error}
        {...(externalOnChange ? { defaultValue: undefined } : {})}
      />
      {error ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      {description ? <Description>{description}</Description> : null}
    </Field>
  );
}
